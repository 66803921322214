import React from "react";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";

export default function Header() {
  return (
    <>

      <div className="progress-wrap cursor-pointer" onContextMenu={(e) => e.preventDefault()}>
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
      {/* Lines */}
      <div className="content-lines-wrapper" onContextMenu={(e) => e.preventDefault()}>
        <div className="content-lines-inner">
          <div className="content-lines" />
        </div>
      </div>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg" onContextMenu={(e) => e.preventDefault()}>
        <div className="container">
          {/* Logo */}
          <div className="logo-wrapper">
            <Link className="logo" to="/">
              {" "}
              <img
                src="assets/images/logo.png"
                className="logo-img"
                alt="Shiv Design 3D Architecture & Interior"
              />{" "}
            </Link>
          </div>
          {/* Button */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar"
            aria-controls="navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {" "}
            <span className="navbar-toggler-icon">
              <i className="ti-menu" />
            </span>{" "}
          </button>
          {/* Menu */}
          <div className="collapse navbar-collapse" id="navbar">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item dropdown">
                {" "}
                {/* <NavLink className="nav-link" activeClassName="active" to="/">
                  Home
                </NavLink> */}
                <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about-us">
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/portfolio">
                  Portfolio
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/gallery">
                  Gallery
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/inquiry">
                  Inquiry
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact-us">
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
