import React from "react";

import { Link } from "react-router-dom";

export default function Banner() {
  return (
    <>
      <div
        className="banner-header valign bg-img bg-fixed"
        data-overlay-dark="4"
        style={{ backgroundImage: `url("assets/images/banner.jpg")` }}
        onContextMenu={(e) => e.preventDefault()}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 caption mt-60">
              <div className="subtitle">Gallery</div>
              <div className="title">
              Images <span>and Videos</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
