import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function InquiryForm() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [subject, setsubject] = useState("");
  const [message, setmessage] = useState("");

  const [nameerror, setnameerror] = useState("");
  const [emailerror, setemailerror] = useState("");
  const [phoneerror, setphoneerror] = useState("");
  const [subjecterror, setsubjecterror] = useState("");
  const [messageerror, setmessageerror] = useState("");

  function insertData() {
    console.log("---", name, email, phone, subject, message);
    if (
      name === "" ||
      email === "" ||
      phone === "" ||
      subject === "" ||
      message === ""
    ) {
      if (name === "") {
        setnameerror("Please enter your full name");
      } else {
        setnameerror("");
      }
      if (email === "") {
        setemailerror("Please enter your email");
      } else {
        setemailerror("");
      }
      if (phone === "") {
        setphoneerror("Please enter your phone number");
      } else {
        setphoneerror("");
      }
      if (subject === "") {
        setsubjecterror("Please enter your subject");
      } else {
        setsubjecterror("");
      }
      if (message === "") {
        setmessageerror("Please enter your message");
      } else {
        setmessageerror("");
      }
    } else {
      axios
        .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
          method: "inquiry",
          submethod: "insert",
          name: name,
          email: email,
          phone: phone,
          subject: subject,
          message: message,
          key: process.env.REACT_APP_KEY,
        })
        .then((res) => {
          if (res.data === "3") {
            toast.error("Duplicate Email_ID", {
              subject: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.success("Insert Successfully", {
              subject: toast.POSITION.TOP_RIGHT,
            });
          }

          console.log("Inquiry Data Inserted Successfully", res.data.data);
        });
    }

    setname("");
    setemail("");
    setphone("");
    setsubject("");
    setmessage("");
  }

  return (
    <>
      <div className="contact section-padding" onContextMenu={(e) => e.preventDefault()}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src="assets/images/inquiry.jpg"
                className="img-fluid"
                alt="Inquiry"
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
            <div className="col-md-6">
              <h4 className="mb-4">
                Have a Question? - <span>Inquiry Now</span>
              </h4>
              <form method="post">
                <div className="row">
                  <div className="col-md-12 form-group">
                    <input
                      name="name"
                      type="text"
                      placeholder="Your Name *"
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                    />
                    {name ? (
                      ""
                    ) : (
                      <span className="text-danger">{nameerror}</span>
                    )}
                  </div>
                  <div className="col-md-12 form-group">
                    <input
                      name="email"
                      type="email"
                      placeholder="Your Email *"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                    {email ? (
                      ""
                    ) : (
                      <span className="text-danger">{emailerror}</span>
                    )}
                  </div>
                  <div className="col-md-12 form-group">
                    <input
                      name="phone"
                      type="text"
                      placeholder="Your Number *"
                      value={phone}
                      onChange={(e) => setphone(e.target.value)}
                    />
                    {phone ? (
                      ""
                    ) : (
                      <span className="text-danger">{phoneerror}</span>
                    )}
                  </div>
                  <div className="col-md-12 form-group">
                    <input
                      name="subject"
                      type="text"
                      placeholder="Subject *"
                      value={subject}
                      onChange={(e) => setsubject(e.target.value)}
                    />
                    {subject ? (
                      ""
                    ) : (
                      <span className="text-danger">{subjecterror}</span>
                    )}
                  </div>
                  <div className="col-md-12 form-group">
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="4"
                      placeholder="Message *"
                      value={message}
                      onChange={(e) => setmessage(e.target.value)}
                    ></textarea>
                    {message ? (
                      ""
                    ) : (
                      <span className="text-danger">{messageerror}</span>
                    )}
                  </div>
                  <div>
                    <br />
                    <button
                      type="button"
                      className="button-light"
                      onClick={insertData}
                    >
                      Send Now
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
