import React from "react";
import { Link } from "react-router-dom";

export default function Subscribe() {
  return (
    <>       
        <section className="lets-talk">
          <div
            className="background bg-img bg-fixed section-padding"
            style={{ backgroundImage: `url("assets/images/subscribe.jpg")` }}
            data-overlay-dark={6}
            onContextMenu={(e) => e.preventDefault()}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-4 mb-30">
                  <div className="sub-title border-bot-dark">Contact Us</div>
                </div>
                <div className="col-md-8">
                  <div className="section-title">
                    For more information please follow us.
                  </div>
                  <Link to="/contact-us" className="button-light">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

    </>
  );
}
